<style scoped lang="less">
	.turntableActivities {
		.dialog-footer{
			text-align: center;
		}
		.onelineClass{
			word-break: break-all;
			display: -webkit-box;
			-webkit-line-clamp:1;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
			
	}
</style>
<template>
	<div class="turntableActivities">
		<div class="filter-container">
			<div class="filter-item">
				<label class="label" style='display: inline-block;width: 75px;'>关键字: </label>
				<el-input v-model="searchKey" style="width: 200px;" placeholder="活动名称"></el-input>
			</div>

			<div class="filter-item">
				<label class="label">活动状态: </label>
				<el-select v-model="state" style="width: 200px;" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="(item,index) in stateOptions" :key="index" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>

			<div class="filter-item">
				<el-button type="primary" style="margin-right:10px;width:90px;" @click="handleFilter">查询</el-button>
			</div>

		</div>

		<div class="tab-box">
			<el-table :data="list" v-loading="loading" row-key="getRowKey" ref="compSelectProTable">
				<el-table-column width="55px">
					<template scope="scope">
						 <el-radio :label="scope.row.Id" v-model="templateRadio"  @change.native="getTemplateRow(scope.row)">{{&nbsp;}}</el-radio>
					</template>
				</el-table-column>

				<el-table-column prop="Name" label="活动名称" width="250px">
					<template slot-scope="scope">
						<div class="onelineClass">{{scope.row.Name}}</div>
					</template>
				</el-table-column>				
				
				<el-table-column prop="StartTime" label="活动时间">
					<template slot-scope="scope">
						{{scope.row.StartTime}} 至
						{{scope.row.EndTime}}
					</template>
				</el-table-column>
				<el-table-column prop="ActivityStateShow" label="活动状态"></el-table-column>
			</el-table>
		</div>

		<div class="pagination" style="text-align: center;">			
			<el-pagination v-if="total" style="margin-top:20px;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="pageIndex" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total">
			</el-pagination>
		</div>

		<div class="dialog-footer" style="margin-top:20px">
			<el-button class="button" @click="saveSelectactivList" :loading="btnloading" type="primary">确定</el-button>
		</div>


	</div>
</template>

<script>
	import {
		activityWheelSurfPopupList
	} from '@/api/goods'
	export default {
		components: {},
		props: {
			selectActivedata: { //需要选中的商品列表
				type: Array,
				default: () => {
					return []
				}
			},
			checkShowId: {
			  type:Number,
			}

		},
		data() {
			return {
				type: '',
				pageIndex: 1,
				pageSize: 10,
				total: 0,
				list: [],
				loading: false,
				searchKey: '',
				state: null,
				stateOptions: [{
						value: 0,
						label: '未开始'
					},
					{
						value: 1,
						label: '进行中'
					},
				],
				selectActiveData: {},
				templateRadio: '',
				couponCheckedId: 0,
				btnloading:false,
			};
		},
		methods: {
			async getData() {
				try {
					this.loading = true;
					const params = {
						KeyWord: this.searchKey,
						ActivityState: this.state,
						Skip: (this.pageIndex - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					const result = await activityWheelSurfPopupList(params);

					this.list = result.Result.Results;
					this.total = result.Result.Total;
					this.templateRadio=this.checkShowId
					this.list.map(item => {
						if (this.selectActivedata.length && (item.Id == this.selectActivedata[0].Id)) {
							item.checked = true
							this.selectActiveData = item;
						} else {
							item.checked = false
						}
						return item
					})
					// console.log(this.list)

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.loading = false;
				}

			},
			getTemplateRow(row) {
				this.selectActiveData = row
			},
			handleFilter() {
				this.pageIndex = 1;
				this.getData();
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize= val;
				this.getData();
			},
			// 翻页
			handleCurrentChange(val) {
				this.pageIndex = val;
				this.getData()
			},
			getRowKey(row) {
				return row.Id
			},
			saveSelectactivList() {
				this.btnloading = true
				this.$emit('getActivetList', this.selectActiveData)
				setTimeout(()=>{
					this.btnloading=false
				},1000)
			},
		},
		watch: {},
		mounted() {

		},
		created() {
			this.getData();
		},
		dispatch() {

		}
	};
</script>

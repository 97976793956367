<template>
  <div>
    <el-form :model="ruform" :rules="rules" ref="couponstablecomponents">
      <el-table :data="ruform.tablelist">
        <el-table-column prop="CouponName" label="优惠券" width="200px">
          <template slot-scope="scope">
            <div class = "fonthidden">{{scope.row.CouponName}}</div>
            <div style="color:#f56c6c;font-size:12px;margin-top:3px">{{scope.row.State}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="UseTypeValue" label="类型" width="120px"></el-table-column>
        <el-table-column prop="CouponContent" label="优惠内容"></el-table-column>
        <el-table-column prop="ActivityRangeTypeValue" label="适用商品"></el-table-column>
        <el-table-column prop="" label="赠送数量/人" width="200">
          <template slot-scope="scope">
            <el-form-item label-width="0px" :prop="'tablelist.'+scope.$index+'.GiftCount'" :rules="rules.GiftCount"
              style="margin:0px 0px 20px 0px" :key="'GiftCount'+currentPage+sizepage+scope.$index">
              <el-input :disabled="disabled" :min="1" :max="99" v-model="scope.row.GiftCount"
              style="width:120px" :precision="0" :controls="false"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="scope">
            <el-button :disabled="disabled" type="text" style="color:#f56c6c" @click="delshop(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div style="margin-top:10px;text-align:right" v-if="total">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5,10, 20, 30]"
        :page-size="sizepage"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>  
    </div>            
  </div>
</template>

<script>
export default {
  props: {
    list:{
      type:Array,
      default:(()=>{
        return []
      })
    },
    disabled:{
      type:Boolean,
      default:false,
    },
  },
  data () {
    var checkGiftCount = (rule, value, callback) => {
      // console.log(value)
				if (value < 1 || value > 99 || value != parseInt(value)) {
					callback(new Error('数量请设置在1~99之间，整数 '))
				}else {
					callback()
				}
			};
    return {
      alllist:[],
      ruform:{
        tablelist:[]
      },
      currentPage:1,
      sizepage:5,
      total:null,

      rules:{
        GiftCount:[{
          required: true,
          validator: checkGiftCount,
          trigger: 'blur'
				}]
      }
    }
  },
  watch:{
    list(){
      this.alllist = JSON.parse(JSON.stringify(this.list.map((v)=>{
        // v.RelationId = v.Id
        return v
      })))
      // console.log(this.alllist)
      this.gettablelist()
    }
  },
  created () {
    this.alllist = JSON.parse(JSON.stringify(this.list.map((v)=>{
        // v.RelationId = v.Id
        return v
      })))
    this.gettablelist()
  },
  methods: {
    changeGiftCount(e,row){
      // console.log(e)
      // this.alllist.map((v)=>{
      //   if(v.Id==row.Id){
      //     v.GiftCount = e
      //   }
      //   return v
      // })
      // console.log()
    },
    validate(){
      // console.log('eqew')
      return new Promise((res,rej)=>{
        this.$refs.couponstablecomponents.validate((val)=>{
          if(val){
            res(this.alllist)
          }else{
            rej(false)
          }
        })
      })
      
    },
    validForm(){
      // console.log('eqew')
      return new Promise((res,rej)=>{
        this.$refs.couponstablecomponents.validate((val)=>{
          if(val){
            let num
            for(let i=0;i<this.alllist.length;i++){
              let value = this.alllist[i]
              if(value.GiftCount < 1 || value.GiftCount > 99 || value.GiftCount != parseInt(value.GiftCount)){
                num=i
                break
              }
            }
            // console.log(this.alllist.map(v=>v.GiftCount))
            if(num+1){
              // console.log(num+1,'xx')
              this.currentPage = Math.ceil((num+1)/this.sizepage)
              this.handleCurrentChange(this.currentPage)
              // console.log(this.currentPage,num+1,this.sizepage)
              this.$nextTick(()=>{
                num = undefined
                this.$refs.couponstablecomponents.validate('GiftCount')
              })
              rej(false)
            }else{
              res(this.alllist)
            }
          }else{
            rej(false)
          }
        })
      })
      
    },
    returnList(){
      return this.alllist
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.sizepage = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage=e
      this.gettablelist()
    },
    delshop(e){
      this.alllist = this.alllist.filter((v)=>{
        return v.RelationId!=e.RelationId
      })
      // console.log(this.alllist)
      this.$emit('delshop',this.alllist)
      this.gettablelist()
    },
    gettablelist(){
      this.total = this.alllist.length
      let min = (this.currentPage-1)*this.sizepage
      let max = this.currentPage*this.sizepage

      this.ruform.tablelist = this.alllist.filter((v,i)=>{
        // debugger
        return i >= min && i <max
      })
      if(!this.ruform.tablelist||!this.ruform.tablelist.length){//如果删除之后本页没有数据
        if(this.currentPage>1){//若页面不是第一页  则页面-1重新获取
          this.currentPage-=1
          this.gettablelist()
        }
      }

      // console.log(this.ruform.tablelist)
    },
  },
}
</script>

<style lang = "less" scoped>
  .fonthidden{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  }
</style>